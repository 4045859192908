const CONFIG = {
    BACKEND_URL: "https://pm-backend-0jc4.onrender.com",
    RAZORPAY_KEY_ID: "rzp_test_TQV9qqM4mQbq82",
    RAZORPAY_SCRIPT: "https://checkout.razorpay.com/v1/checkout.js"
}

// const PRODUCTION_CONFIG = {
//     BACKEND_URL: "https://pm-backend-0jc4.onrender.com",
//     RAZORPAY_KEY_ID: "rzp_live_PM74vFGDJekjUp",
//     RAZORPAY_SCRIPT: "https://checkout.razorpay.com/v1/checkout.js"
// }

module.exports = { CONFIG: CONFIG }