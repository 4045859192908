import React, { useEffect, useState } from 'react';
import { TextField, Chip, Button, InputAdornment, Snackbar, Alert, MenuItem, Select, IconButton } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import UploadIcon from '@mui/icons-material/Upload';
import '../styles/BlogContentCreation.css'; // Custom CSS file for additional styling

// FormInput component for reusable form fields
const FormInput = ({ label, value, setValue, type = 'text', required = true, placeholder }) => (
    <div className="form-group">
        <TextField
            fullWidth
            variant="outlined"
            label={label}
            type={type}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
            required={required}
        />
    </div>
);

// QuillEditor component for reusable rich text editor
const QuillEditor = ({ label, value, setValue, placeholder }) => (
    <div className="form-group">
        <label>{label}</label>
        <ReactQuill
            value={value}
            onChange={setValue}
            placeholder={placeholder}
            modules={{
                toolbar: [
                    [{ header: '1' }, { header: '2' }, { font: [] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link', 'image', 'video'],
                    ['clean'],
                ],
            }}
            formats={[
                'header',
                'font',
                'size',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'bullet',
                'link',
                'image',
                'video',
            ]}
            style={{ minHeight: '200px' }}
        />
    </div>
);

const BlogContentCreation = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);
    const [overview, setOverview] = useState('');
    const [details, setDetails] = useState('');
    const [price, setPrice] = useState(999);
    const [discountPrice, setDiscountPrice] = useState(199);
    const [imageUrl, setImageUrl] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [productUrl, setProductUrl] = useState('');
    const [productId, setProductId] = useState(5855705);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [imageOptions, setImageOptions] = useState([]);

    useEffect(() => {
        setImageOptions([
            {
                "productId": 5913686,
                "url": "https://storeassets.im-cdn.com/media-manager/placementmentor/2QDn5bgBTQOucPLDrd7r_KN25JmaQ4e4USbepLfuQ_2f017a62cedd4633addb53bde6fceb16_0x0_webp.webp",
                "name": "Elitmus Aptitude Questions | Study Materials"
            },
            {
                "productId": 5913685,
                "url": "https://storeassets.im-cdn.com/media-manager/placementmentor/2IVn86ERQpGmg1TjnSAv_Y0jQRHX8SJOD9tdRRz2N_a44ddd3aa21f42f0b0dba5c7cc6d4be5_248x248_webp.webp",
                "name": "Capgemini Aptitude Questions | Study Materials"
            },
            {
                "productId": 5913684,
                "url": "https://storeassets.im-cdn.com/media-manager/placementmentor/SKXlnGoDReasrlDbuFHI_6y6ZIfk9TIO3snwHBvmw_1e4474a30f8349859f3dfdcddd333ef1_0x0_webp.webp",
                "name": "TCS Aptitude Questions | Study Materials"
            },
            {
                "productId": 5913682,
                "url": "https://storeassets.im-cdn.com/media-manager/placementmentor/SPARt6c6TfiHJ2ksdPfA_FxlOl4HQPaGrbcPf0Eag_221906c3a0b84a60b5d087de4970ce56_248x248_webp.webp",
                "name": "CTS Aptitude Questions | Study Materials"
            },
            {
                "productId": 5913680,
                "url": "https://storeassets.im-cdn.com/media-manager/placementmentor/fpFuX1vRZ2fMJfa1XRTg_BH2RqyPQZKllHFjrDPGg_dcfc269e2f694dc2bb42cb56fc19be59_0x0_webp.webp",
                "name": "Cocubes Aptitude Questions | Study Materials"
            },
            {
                "productId": 5913679,
                "url": "https://storeassets.im-cdn.com/media-manager/placementmentor/lW9WUrBRPOJXdnc8QnhA_0ugHjvS7Sh6CxmC3Rk2e_b925e2060e764cba9e7de2e07223158b_0x0_webp.webp",
                "name": "Tech Mahindra Aptitude Questions | Study Materials"
            },
            {
                "productId": 5913678,
                "url": "https://storeassets.im-cdn.com/media-manager/placementmentor/736ukpWnTo2dZurktS4W_W3fDa4ILR2NhsCmqq5sb_bcfa72c2045146ef89513ff77ceb24a1_248x248_webp.webp",
                "name": "Wipro Aptitude Questions"
            },
            {
                "productId": 5913641,
                "url": "https://storeassets.im-cdn.com/media-manager/placementmentor/XQUhd1RwQvub3qLdckmi_ZKdGMsYQa2Hziauzk9t1_7ad1cd82b29047889f4c8b84c8cbb811_0x0_webp.webp",
                "name": "Accenture Aptitude Questions | Study Materials"
            },
            {
                "productId": 5913640,
                "url": "https://storeassets.im-cdn.com/media-manager/placementmentor/t0eD06lT9GsDJLn2lZJB_aHK1FrMQq6CfjaJmivbw_a97cd2c654fc4e35a69456309ff9c81a_0x0_webp.webp",
                "name": "Infosys Aptitude Questions & Study Materials"
            },
            {
                "productId": 5855705,
                "url": "https://storeassets.im-cdn.com/media-manager/placementmentor/8HJwe6PRr2AW7yjlRBH0_7jWYuulFQqW7NYn5HX1I_37612961c0a14f62b17ebc16c7125cd7_0x0_webp.webp",
                "name": "AMCAT Mastery Kit: Essential Study Material & Sample Papers"
            },
            {
                "productId": 5921728,
                "url": "https://storeassets.im-cdn.com/media-manager/placementmentor/Uhm4JSYYS9WZLZLkcfkE_NEET_0x0_webp.webp",
                "name": "NEET"
            },
            {
                "productId": 5935891,
                "url": "https://storeassets.im-cdn.com/media-manager/placementmentor/4xm1GpZyR8SCgjfNsTKQ_DALL%C2%B7E%202024-12-11%2012.29.44%20-%20A%20professional%20and%20inviting%20image%20of%20a%20young%20woman%20holding%20books%20in%20an%20academic%20setting,%20symbolizing%20study%20and%20preparation.%20She%20is%20smiling%20confidently_0x0_webp.webp",
                "name": "TNSET Question Papers & Study Material"
            }
        ]
        );
    }, [])

    const handleTagAdd = (event) => {
        if (event.key === 'Enter' && event.target.value) {
            setTags([...tags, event.target.value]);
            event.target.value = '';
        }
    };

    const handleTagDelete = (tagToDelete) => {
        setTags(tags.filter(tag => tag !== tagToDelete));
    };

    const handleCreate = () => {
        // Validation
        if (!title || !description || !overview || !details || !price || !imageUrl || !productUrl || !productId) {
            setSnackbarMessage('All fields are required!');
            setSnackbarOpen(true);
            return;
        }

        const blogData = {
            id: productId,
            title,
            description,
            tags,
            overview,
            details,
            discountPrice,
            price,
            images: [imageUrl],
            url: productUrl,
        };

        // Function to safely convert the object to a Base64 encoded string
        const toBase64 = (str) => {
            return btoa(unescape(encodeURIComponent(str)));
        };

        // Convert blogData to a Base64 encoded string
        const encodedData = toBase64(JSON.stringify(blogData, null, 2));

        // Create the final JSON structure
        const finalData = { data: encodedData };

        // Create a blob from the final JSON structure
        const blob = new Blob([JSON.stringify(finalData, null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = productUrl?.replace('https://shop.placementmaster.in/question-paper/', '') || 'blog-content.json';
        a.click();
        URL.revokeObjectURL(url);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const jsonData = JSON.parse(e.target.result);
                    const product = JSON.parse(decodeURIComponent(escape(atob(jsonData.data))));
                    const json = product
                    setProductId(json.id || '');
                    setTitle(json.title || '');
                    setDescription(json.description || '');
                    setTags(json.tags || []);
                    setOverview(json.overview || '');
                    setDetails(json.details || '');
                    setDiscountPrice(json.discountPrice || '');
                    setPrice(json.price || '');
                    setImageUrl((json.images && json.images[0]) || '');
                    setSelectedImage((json.images && json.images[0]) || '');
                    setProductUrl(json.url || '');
                } catch (error) {
                    setSnackbarMessage('Error parsing JSON file.');
                    setSnackbarOpen(true);
                }
            };
            reader.readAsText(file);
        }
    };

    return (
        <div className="blog-content-creation">
            <h1>Placement Master | Blogging</h1>
            <div className="upload-container">
                <input
                    accept=".json"
                    id="file-upload"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                />
                <label htmlFor="file-upload">
                    <IconButton color="primary" component="span">
                        <UploadIcon />
                    </IconButton>
                </label>
            </div>
            <div className="form-preview-container">
                <div className="form-container">
                    <form className="blog-form">
                        <FormInput label="Product ID" value={productId} setValue={setProductId} placeholder="Enter product ID" />
                        <FormInput label="Title" value={title} setValue={setTitle} placeholder="Enter blog title" />
                        <FormInput label="Description" value={overview} setValue={setOverview} placeholder="Enter blog description" />
                        <FormInput label="Discount Price" type="number" value={discountPrice} setValue={setDiscountPrice} placeholder="Enter discounted price" />
                        <FormInput label="Actual Price" type="number" value={price} setValue={setPrice} placeholder="Enter actual price" />
                        <FormInput label="Product URL" value={productUrl} setValue={setProductUrl} placeholder="Enter product URL" />

                        <div className="form-group">
                            <label htmlFor="tags">Tags</label>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter tags and press Enter"
                                onKeyDown={handleTagAdd}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <div className="tags-container">
                                                {tags.map(tag => (
                                                    <Chip
                                                        key={tag}
                                                        label={tag}
                                                        onDelete={() => handleTagDelete(tag)}
                                                        style={{ margin: '4px', backgroundColor: '#f58020', color: '#fff' }}
                                                    />
                                                ))}
                                            </div>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <QuillEditor label="Overview" value={description} setValue={setDescription} placeholder="Write your blog overview here..." />
                        <QuillEditor label="Details" value={details} setValue={setDetails} placeholder="Write detailed content here..." />

                        <div className="form-group">
                            <label htmlFor="image-url">Choose Product Image</label>
                            <Select
                                fullWidth
                                value={selectedImage}
                                onChange={(e) => {
                                    const image = imageOptions.find(img => img.url === e.target.value);
                                    setSelectedImage(e.target.value);
                                    setImageUrl(image?.url || '');
                                    setProductId(image?.productId)
                                }}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select Image' }}
                            >
                                {imageOptions.map((image) => (
                                    <MenuItem key={image.url} value={image.url}>
                                        <img src={image.url} alt={image.name} style={{ width: '100px' }} />
                                        {image.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCreate}
                        >
                            Create Blog
                        </Button>
                    </form>
                </div>

                <div className="preview-container">
                    <h2>Preview</h2>
                    <div className="preview">
                        <h3>{title}</h3>
                        <img src={imageUrl} alt="Preview" style={{ width: '100%' }} />
                        <p>{overview}</p>

                        <div className="price-info">
                            <span className="discount-price">₹{discountPrice}</span>
                            <span className="original-price">₹{price}</span>
                        </div>

                        <div className="tags-preview">
                            {tags.map(tag => (
                                <Chip
                                    key={tag}
                                    label={tag}
                                    style={{ margin: '4px', backgroundColor: '#f58020', color: '#fff' }}
                                />
                            ))}
                        </div>


                        <p><strong>Product URL:</strong> <a href={productUrl} target="_blank" rel="noopener noreferrer">{productUrl}</a></p>


                        <div className="overview-preview">
                            <h4>Overview</h4>
                            <div dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                        <div className="details-preview">
                            <h4>Details</h4>
                            <div dangerouslySetInnerHTML={{ __html: details }} />
                        </div>
                    </div>
                </div>
            </div>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="error">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default BlogContentCreation;
