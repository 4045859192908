import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, Divider, Typography, IconButton } from '@mui/material';
import { Menu, ExpandLess, ExpandMore, Circle } from '@mui/icons-material';
import { MNC_COMPANIES } from '../data/sidebarData';
import { MENU_ITEMS } from '../data/menuConfig';
import '../styles/Sidebar.css';
import { useNavigate } from 'react-router-dom';
import TrustTag from '../components/TrustTag';

const Sidebar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activePath, setActivePath] = useState('/');
  const [mncOpen, setMncOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigation = (path) => {
    setActivePath(path);
    if (!path.includes('question-paper')) {
      setMncOpen(false);
    }
    navigate(path);
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const handleMncMenuClick = () => {
    setMncOpen(!mncOpen);
    if (!mncOpen) {
      const firstMncPath = `/question-paper/${MNC_COMPANIES[0].name.toLowerCase().replace(/\s+/g, '-')}`;
      setActivePath(firstMncPath);
      navigate(firstMncPath);
    }
  };

  const renderMNCSubMenu = () =>
    MNC_COMPANIES.map((company, index) => (
      <ListItem
        key={index}
        button
        onClick={() => handleNavigation(`/question-paper/${company.name.toLowerCase().replace(/\s+/g, '-')}`)}
        className={`sidebar-submenu-item ${activePath === `/question-paper/${company.name.toLowerCase().replace(/\s+/g, '-')}` ? 'active' : ''}`}
      >
        <ListItemIcon>
          <Circle />
        </ListItemIcon>
        <ListItemText primary={company.name} />
      </ListItem>
    ));

  const drawerContent = (
    <div className="sidebar-container">
      <div className="sidebar-logo">
        <img src="https://placementmaster.in/assets/img/logo.png" alt="Logo" className="logo-img" />
        <div className="logoText">Placement Master</div>
      </div>
      <Divider className="sidebar-divider" />
      <List>
        {MENU_ITEMS.map((item, index) => {
          if (item.subMenu) {
            return (
              <div key={index}>
                <ListItem button onClick={handleMncMenuClick} className={`sidebar-menu-item ${mncOpen ? 'active' : ''}`}>
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                  {mncOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={mncOpen} timeout="auto" unmountOnExit>
                  {renderMNCSubMenu()}
                </Collapse>
              </div>
            );
          }
          return (
            <ListItem
              key={index}
              button
              onClick={() => handleNavigation(item.path)}
              className={`sidebar-menu-item ${activePath === item.path ? 'active' : ''}`}
            >
              <ListItemIcon>
                <item.icon />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          );
        })}
      </List>
      <Divider className="sidebar-divider" />
      <div className="powered-by">
        <Typography variant="body2">Powered by Placement Master</Typography>
      </div>
    </div>
  );

  return (
    <>
      {/* Mobile Header */}
      <div className="mobile-header">
        <IconButton className="menu-button" onClick={handleDrawerToggle}   aria-label="Open navigation menu"> 
          <Menu />
        </IconButton>
        <header className="app-header">
          <TrustTag variant="header" />
        </header>
        {/* <TrustTag variant="header" /> */}
        <div className="logo">
          <img src="https://placementmaster.in/assets/img/logo.png" alt="Logo" className="logo-img" />
        </div>
      </div>

      {/* Desktop & Tablet Drawer (Permanent for 768px and above) */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },  // Permanent from 768px and above
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 260 },
        }}
        open
      >
        {drawerContent}
      </Drawer>

      {/* Mobile/Tablet Drawer (Temporary for below 768px) */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: 'block', md: 'none' },  // Temporary below 768px
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 260 },
        }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default Sidebar;
