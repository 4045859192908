import React from 'react';

const CategoryFilter = ({ category, setCategory }) => (
  <select onChange={(e) => setCategory(e.target.value)} value={category}>
    <option value="All">All Categories</option>
    <option value="MNC">MNC</option>
  </select>
);

export default CategoryFilter;
