import React, { Fragment, useEffect, useState } from "react";
import axios from 'axios';
import '../styles/PaymentSuccess.css';
import { CONFIG } from "../config"

const PaymentSuccess = () => {

    const [orderDetails, setOrderDetails] = useState(null); // Changed initial state to null
    
    const [loading, setLoading] = useState(true); // To track loading state

    const handleDownload = (URL) => {
        const fileUrl = URL; // The URL of the file to download
        const link = document.createElement("a"); // Create a new anchor element
        link.href = fileUrl; // Set the href attribute to the file URL
        link.download = ''; // Optionally set a download attribute to specify the filename (blank will use the default filename from the URL)
        link.click(); // Simulate a click to trigger the download
    };

    const fetchOrderInformation = async (razorpayOrderId) => {

        try {

            const responseData = await axios.post(`${CONFIG.BACKEND_URL}/fetch-payment`, { razorpayOrderId });

            setOrderDetails(responseData?.data?.data);
    
            setLoading(false);
    
            return responseData?.data?.data;
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {

        const orderId = window.location.pathname.split("/payment-success/")[1]

        if (orderId && !orderDetails) { // Only fetch if not already fetched
            fetchOrderInformation(orderId);
        }

        return () => {

        }
    }, [orderDetails])

    const { notes = {}, status , URL = "" } = orderDetails || {};

    const { image, product_name: title, product_description: description } = notes;


    if (loading) {
        return <div>Loading...</div>; // Display a loading state
    }

    return (
        orderDetails?.status ? <div className="payment-success-container">
            {status === "paid" ? <Fragment>
                <h2 className="success-title">Payment Successful!</h2>
                <p className="welcome-note">Thank you for your purchase. We appreciate your business.</p>
                <div className="product-details-success">
                    <img
                        src={image}
                        alt="Product"
                        className="product-image-success"
                    />
                    <div className="product-info">
                        <h3 className="product-title">{title}</h3>
                        <p className="product-description" dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </div>

                <button className="download-btn" onClick={() => {
                    handleDownload(URL)
                }}>
                    <span>Download</span>
                </button>
            </Fragment> : <Fragment>
                <h2 className="failure-title">Payment Failed!</h2>
                <p className="failure-note">Unfortunately, your payment could not be processed. Please try again.</p>

                <div className="failure-details">
                    <img
                        image
                        src={image || "https://via.placeholder.com/150"}
                        alt="Failure"
                        className="failure-image"
                    />
                    <div className="failure-info">
                        <h3 className="failure-title">{title}</h3>
                        <p className="product-description" dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </div>

                <button className="retry-btn" onClick={() => window.location.reload()}>
                    <span>Try Again</span>
                </button>
            </Fragment>}
        </div> : null
    );
};

export default PaymentSuccess;
