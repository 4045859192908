import React, { useEffect , useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { DeliveryDiningOutlined, MoneyOff, SupportAgent } from '@mui/icons-material';
import '../styles/HomePage.css';
import { assessmentTests, competitiveExams } from '../data/products';
import MetaTags from '../components/MetaTags';
import { fetchAndTransformProductListing } from '../utils';
import SingleProductHighlight from "../components/SingleProductHighlight"

const ProductSection = ({ title, items }) => (
  <div className="product-section">
    <h2>{title}</h2>
    <div className="products-list">
      {items.map((item, index) => (
        <a key={index} href={item.path} className="product">
          <h3>{item.name}</h3>
          <p>Get the latest materials for {item.name}.</p>
        </a>
      ))}
    </div>
  </div>
);

const HomePage = () => {

  const [companyPlacementPapers, setCompanyPlacementPapers] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const products = await fetchAndTransformProductListing();
      setCompanyPlacementPapers(products);
    };
    fetchProducts();
  }, []);


  return (
    <div className="home-page">

      <MetaTags
        title={`Home - Placement Master`}
        description={`Browse our collection of placement papers, assessment tests, and competitive exam preparation materials!`}
        images={`https://placementmaster.in/assets/img/logo.png`}
        url={`https://shop.placementmaster.in/home`}
      />
      {/* Welcome Section */}
      <div className="welcome-section">
        <h1>Welcome to the Placement Master Store</h1>
        <p>Browse our collection of placement papers, assessment tests, and competitive exam preparation materials!</p>
      </div>

      {/* Banner Section */}
      <div className="banner">
        <Carousel autoPlay infiniteLoop showThumbs={false}>
          <div>
            <img src="../../../banner1.jpg" alt="Banner 1" />
          </div>
          <div>
            <img src="../../../banner2.jpg" alt="Banner 2" />
          </div>
          <div>
            <img src="../../../banner3.jpg" alt="Banner 3" />
          </div>
        </Carousel>
      </div>

      {/* Services Section */}
      <div className="services">
        <div className="service">
          <DeliveryDiningOutlined className="service-icon" />
          <h3>Quick Download & Email Confirmation</h3>
          <p>Access your question papers instantly after purchase, along with a download link delivered directly to your inbox!</p>
        </div>
        <div className="service">
          <MoneyOff className="service-icon" />
          <h3>Easy Refunds</h3>
          <p>If there's an issue with your purchase, we'll ensure a hassle-free refund.</p>
        </div>
        <div className="service">
          <SupportAgent className="service-icon" />
          <h3>24/7 Support</h3>
          <p>Our support team is here to help you anytime, anywhere!
</p>
        </div>
      </div>

      <SingleProductHighlight product={{
        id: 1,
        name: 'AMCAT Study Material & Sample Papers',
        image: 'https://storeassets.im-cdn.com/media-manager/placementmentor/8HJwe6PRr2AW7yjlRBH0_7jWYuulFQqW7NYn5HX1I_37612961c0a14f62b17ebc16c7125cd7_0x0_webp.webp',
        description: 'Prepare with the best practice papers for the AMCAT Study Material. Get a head start in your career!',
        price: '149',
        link: 'https://shop.placementmaster.in/question-paper/amcat-sample-papers',
      }} />

      {/* Company Placement Papers Section */}
      <ProductSection title="Company Placement Papers" items={companyPlacementPapers} />

      {/* Assessment Tests Section */}
      <ProductSection title="Assessment Tests" items={assessmentTests} />

      {/* Competitive Exams Preparation Section */}
      <ProductSection title="Competitive Exams Preparation" items={competitiveExams} />
    </div>
  );
};

export default HomePage;
