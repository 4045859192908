import React from 'react';
import '../styles/ProductPage.css'; // Import CSS file
// import RazorpayPayment from "./RazorpayPayment"
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const ProductDetails = ({ title, description, tags, details, discountPrice, originalPrice, id, image }) => {

    let originalId = (`${id}` === "1021762") ? "5855705" : `${id}`;

    return (
        <div className="product-details">
            <h1>{title}</h1>
            <p className="product-description" dangerouslySetInnerHTML={{ __html: description }} />
            <div className="product-pricing">
                <span className="discount-price">₹{discountPrice}</span>
                <span className="original-price">₹{originalPrice}</span>
            </div>

            <div className="product-tags">
                {tags.map((tag, index) => (
                    <span key={index} className="product-tag">{tag}</span>
                ))}
            </div>

            {/* Buy Button */}
            <div className="product-buy">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    sx={{
                        backgroundColor: '#f58020',
                        color: '#ffffff',
                        width: 'max-content',
                        borderRadius: '30px',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#ff7b26',
                        },
                    }}
                >
                    <a
                        href='https://placementmentor.myinstamojo.com'
                        data-store-name="placementmentor"
                        data-domain="https://placementmentor.myinstamojo.com"
                        data-id={originalId}
                        rel="im-new-checkout"
                        data-text={`DOWNLOAD NOW`}
                        data-css-style="background: #f58020;color: #ffffff;border-radius: 30px;font-size: 14px;font-weight: bold;margin: 0px !important;padding: 0px !important;font-family: Arial, sans-serif;"
                    >
                        <span>DOWNLOAD NOW</span>
                    </a>
                </Button>
            </div>

            <div className="product-details-info">
                <p dangerouslySetInnerHTML={{ __html: details }} />
            </div>
        </div>
    );
};

//    {/* <div><RazorpayPayment orderDetails={{
//             amount: discountPrice,
//             image, title, description, tags, details, discountPrice, originalPrice, id
//         }} /></div> */}

export default ProductDetails;
