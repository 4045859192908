import React from 'react';
import axios from 'axios';
import { Facebook, Twitter, LinkedIn, WhatsApp } from '@mui/icons-material';
import ProductDetails from './ProductDetails';
import ProductImageSection from '../components/ProductImageSection';
import '../styles/ProductPage.css'; // Custom CSS for styling
import MetaTags from '../components/MetaTags';
import { useEffect, useState } from 'react';
import SingleProductHighlight from "../components/SingleProductHighlight"

const ProductPage = () => {

  const [product, setProduct] = useState({});
  // Social Media Share Links
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(product.url)}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(product.url)}&text=${encodeURIComponent(product.title)}`;
  const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(product.url)}&title=${encodeURIComponent(product.title)}`;
  const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(product.title)}%20${encodeURIComponent(product.url)}`;

  const renderProductLinks = () => {
    return <div className="product-share">
      <span className="share-text">Share:</span>
      <a href={facebookShareUrl} target="_blank" rel="noopener noreferrer" className="facebook-icon"   aria-label="Share on Facebook">
        <Facebook />
        <span className="share-text">Facebook</span>
      </a>
      <a href={twitterShareUrl} target="_blank" rel="noopener noreferrer" className="twitter-icon"   aria-label="Share on Twitter">
        <Twitter />
        <span className="share-text">Twitter</span>
      </a>
      <a href={linkedInShareUrl} target="_blank" rel="noopener noreferrer" className="linkedin-icon"   aria-label="Share on Linkedin">
        <LinkedIn />
        <span className="share-text">LinkedIn</span>
      </a>
      <a href={whatsappShareUrl} target="_blank" rel="noopener noreferrer" className="whatsapp-icon"   aria-label="Share on Whatsapp">
        <WhatsApp />
        <span className="share-text">WhatsApp</span>
      </a>
    </div>
  }

  const renderSellingUI = () => {
    return <div className="footer-features">
      <div className="feature">
        <h4>Quick Download & Email Confirmation</h4>
        <p>Access your question papers instantly after purchase, along with a download link delivered directly to your inbox!</p>
      </div>
      <div className="feature">
        <h4>Easy Refunds</h4>
        <p>If there's an issue with your purchase, we'll ensure a hassle-free refund.</p>
      </div>
      <div className="feature">
        <h4>24/7 Support</h4>
        <p>Our support team is here to help you anytime, anywhere!
        </p>
      </div>
    </div>
  }

  const fetchProductDetails = async () => {

    try {
      const urlList = window.location.href.split("/");

      const productFileName = urlList[window.location.href.split("/").length - 1];

      const response = await axios.get(`https://raw.githubusercontent.com/stephenjesus/react-demo/main/${productFileName}.json`);

      const product = JSON.parse(decodeURIComponent(escape(atob(response?.data?.data))));

      console.log(product, 'product');

      setProduct(product);

      // const serverStatus =  await axios.get(`https://pm-backend-0jc4.onrender.com`);

      // console.log(serverStatus.data);

      return product;

    } catch (error) {
      if (error.status === 404) {
        window.location.href = `https://shop.placementmaster.in/about`
      }
      console.log(error, 'error');
      return error;
    }
  }

  useEffect(() => {

    const script = document.createElement('script');

    script.src = "https://manage.instamojo.com/assets/js/pay_button/button.min.js";

    script.async = true;

    script.onload = () => {
      console.log('Instamojo button script loaded successfully', window.IM);
    };

    script.onerror = () => {
      console.error('Failed to load Instamojo button script');
    };

    document.body.appendChild(script);

    fetchProductDetails();

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line
  }, [window.IM])

  return (
    Object.keys(product).length !== 0 ? <div className="product-page">
      <MetaTags
        title={product.title}
        description={product.overview}
        images={product.images[0]}
        url={product.url}
      />
      <div className="product-container">
        {/* Product Image Section */}
        <ProductImageSection
          images={product.images}
          title={product.title}
          productUrl={product.url}
        />

        {/* Product Details Section */}
        <div className="product-details-section">
          <ProductDetails
            title={product.title}
            description={product.description}
            discountPrice={`${product.discountPrice}` === "199" ?  149: product.discountPrice}
            originalPrice={product?.originalPrice || product?.price}
            tags={product.tags}
            id={product.id}
            image={product.images[0]}
            details={product.details}
          />
          <br></br>
          {renderProductLinks()}
          <br></br>
        </div>
      </div>


      {renderSellingUI()}

      <SingleProductHighlight product={{
        id: 1,
        name: 'AMCAT Study Material & Sample Papers',
        image: 'https://storeassets.im-cdn.com/media-manager/placementmentor/8HJwe6PRr2AW7yjlRBH0_7jWYuulFQqW7NYn5HX1I_37612961c0a14f62b17ebc16c7125cd7_0x0_webp.webp',
        description: 'Prepare with the best practice papers for the AMCAT Study Material. Get a head start in your career!',
        price: '149',
        link: 'https://shop.placementmaster.in/question-paper/amcat-sample-papers',
      }} />

    </div> : <div className='loaderBox'>
      <img alt='loader' src='../../../loader.gif'></img>
    </div>
  );
};

export default ProductPage;
