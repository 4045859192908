import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import SalesBanner from './components/SalesBanner';
import Footer from './components/Footer';
import Home from './pages/Home';
import ProductListing from './pages/ProductListing';
import ProductPage from './pages/ProductPage';
import Contact from './pages/Contact';
import RefundUI from './pages/Refund';
import About from './pages/About';
import BlogContentCreation from './pages/BlogContentCreation';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import PaymentSuccess from './pages/PaymentSuccess';
import styled from 'styled-components';

const Content = styled.div`
  margin-left: 250px;
  padding: 20px;
  @media (max-width: 768px) {
    margin-left: 80px;
  }
`;

function App() {
  useEffect(() => {
    if (window.self !== window.top) {
      window.top.location = window.location.href; 
    }

    window.onerror = function(message, source, lineno, colno, error) {
      console.error('Error caught globally:', message, 'from', source, 'at line', lineno);
      // Optionally send error to a logging service
  };
  }, []);
  return (
    <Router>
      <SalesBanner />
      <Sidebar />
      {/* <Navbar /> */}
      <Content>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<ProductListing />} />
          <Route path="/question-paper/:id" element={<ProductPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/return-exchange" element={<RefundUI />} />
          <Route path="/warranty-policy" element={<RefundUI />} />
          <Route path="/faqs" element={<Contact />} />
          <Route path="/blog" element={<BlogContentCreation />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/payment-success/:id" element={<PaymentSuccess />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
        </Routes>
        <Footer />
      </Content>
    </Router>
  );
}

export default App;
