import React from 'react';
import '../styles/Contact.css';
import MetaTags from '../components/MetaTags';

const RefundUI = () => {
  return (
    <div className="contact-us-container">
      <MetaTags
        title={`Refund & Return Policy - Placement Master`}
        description={`Your satisfaction is our top priority. Reach out to us with any questions or concerns, and we'll make sure to assist you as quickly as possible.`}
        images={`https://placementmaster.in/assets/img/logo.png`}
        url={`https://shop.placementmaster.in/contact`}
      />
      <h1>Refund & Return Policy</h1>
      <div className="contact-us-content">
        <div className="contact-us-text">
          <p>
            PLACEMENT MASTER TECH (OPC) PRIVATE LIMITED believes in helping its customers as far as possible, and has therefore established a liberal cancellation policy. Under this policy:
          </p>
          <ul>
            <li>
              Cancellations will be considered only if the request is made immediately after placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the shipping process.
            </li>
            <li>
              PLACEMENT MASTER TECH (OPC) PRIVATE LIMITED does not accept cancellation requests for perishable items like flowers or eatables. However, refund or replacement can be made if the customer establishes that the quality of the product delivered is not good.
            </li>
            <li>
              In case of receipt of damaged or defective items, please report the same to our Customer Service team. The request will only be entertained after the merchant has verified and determined the issue on their end. This should be reported within 7 days of receipt of the products.
            </li>
            <li>
              If the product received is not as shown on the site or does not meet your expectations, you must notify our customer service within 7 days of receiving the product. The Customer Service Team will evaluate your complaint and make an appropriate decision.
            </li>
            <li>
              For products that come with a manufacturer warranty, please refer the issue to the respective manufacturer.
            </li>
            <li>
              In the case of refunds approved by PLACEMENT MASTER TECH (OPC) PRIVATE LIMITED, it will take 16-30 days for the refund to be processed to the end customer.
            </li>
          </ul>
        </div>

        <div className="contact-us-images">
          <img
            src="https://cdn.shopify.com/s/files/1/0590/7873/8097/files/signing-shipping-forms-for-large-box_720x.jpg?v=1628863960"
            alt="Delivery"
            className="contact-us-image"
          />
        </div>
      </div>
    </div>
  );
};

export default RefundUI;
