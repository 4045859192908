// src/data.js
// export const companyPlacementPapers = [
//   { name: "Tata Consultancy Services (TCS)", path: "/tcs-aptitude-questions", image: 'https://media.instamojo.com/imgs/3ce926105bc34e92ab83d0d0da578d64.jpg' },
//   { name: "Infosys", path: "/infosys-aptitude-questions", image: "https://media.instamojo.com/imgs/29b0e693841342a8bbab9dea402d57b1.jpg" },
//   { name: "Wipro", path: "/wipro-aptitude-questions", image: 'https://media.instamojo.com/imgs/d3c98346493c4359ac4dd46ca3c359b0.jpg' },
//   { name: "HCL Technologies", path: "/hcl-technologies-aptitude-questions", image: "https://storeassets.im-cdn.com/products/f504b5/cE8dzq7QQdKbjUIvwzLu_java-sdk-upload-1587696417" },
//   { name: "Tech Mahindra", path: "/tech-mahindra-aptitude-questions", image: "https://media.instamojo.com/imgs/95bd5f7d2ddd4f6bb66d09d8d82cdb9f.jpg" },
//   { name: "Cognizant", path: "/cognizant-aptitude-questions", image: 'https://media.instamojo.com/imgs/add72e63870f4e469652f4d636576dad.jpg' },
//   { name: "Capgemini India", path: "/capgemini-india-aptitude-questions", image: "https://media.instamojo.com/imgs/2c0f23e068a043119c5c711409b8dffa.jpg" },
//   { name: "Mindtree", path: "/mindtree-aptitude-questions", image: "https://media.instamojo.com/imgs/ae0687dbf1a244729dfcdfad772e8f88.jpg" },
//   { name: "L&T Infotech (LTI)", path: "/lt-infotech-aptitude-questions", image: "https://storeassets.im-cdn.com/products/f504b5/FLYwuvZKT5uYnnRhyBSn_LT-Infotech-660_0x0_webp.jpg" },
//   { name: "Mphasis", path: "/mphasis-aptitude-questions", image: "https://storeassets.im-cdn.com/products/f504b5/pIDMHCfkSFWD8NjEfNcQ_mphasis-tile-logo_0x0_webp.png" },
//   { name: "IBM India", path: "/ibm-india-aptitude-questions", image: "https://media.instamojo.com/imgs/62492233f4774defbaa3c25735323702.png" },
//   { name: "Accenture India", path: "/accenture-india-aptitude-questions", image: "https://media.instamojo.com/imgs/42b4106b79a247b8a67ff35686dd85cd.jpg" },
//   { name: "Deloitte", path: "/deloitte-aptitude-questions", image: "https://storeassets.im-cdn.com/products/f504b5/acVRvnCKS0OwqYPLPaab_906c0da366ce4ae48ac96150cba066c5_0x0_webp.png" },
//   { name: "NTT DATA", path: "/ntt-data-aptitude-questions", image: "https://storeassets.im-cdn.com/media-manager/placementmaster/30X4t4uyRFquVqEtHKEY_ndd%20data-_0x0_webp.png" },
//   { name: "Oracle Financial Services Software", path: "/oracle-financial-services", image: "https://storeassets.im-cdn.com/media-manager/placementmaster/S2YTBrgRR7qeDnB3LQ7i_oracle_0x0_webp.webp" },
//   { name: "PwC", path: "/pwc", image: "https://storeassets.im-cdn.com/media-manager/placementmaster/S3VevijaTemdn4tq0YKY_pwc_0x0_webp.png" },
//   { name: "Happiest Minds Technologies", path: "/happiest-minds-technologies", image: "https://storeassets.im-cdn.com/media-manager/placementmaster/BYHvXMh7RruR9k6lpzyD_Happiest-Mind_0x0_webp.jpg" },
//   { name: "Virtusa", path: "/virtusa", image: "https://storeassets.im-cdn.com/media-manager/placementmaster/AizDzA7eTy2W5p6zhgl6_Virtusa_0x0_webp.jpg" },
// ];

export const assessmentTests = [
  { name: "AMCAT", path: "/amcat" },
  { name: "CoCubes", path: "/cocubes" },
  { name: "eLitmus", path: "/elitmus" },
  { name: "TCS NQT", path: "/tcs-nqt" },
  { name: "Infosys Certification", path: "/infosys-certification" },
  { name: "Mettl", path: "/mettl" },
  { name: "Wipro NLTH", path: "/wipro-nlth" },
  { name: "HackerRank", path: "/hackerrank" },
  { name: "CodeChef Certification", path: "/codechef-certification" },
  { name: "Capgemini Exceller", path: "/capgemini-exceller" },
  { name: "Tech Mahindra Off-Campus Drive", path: "/tech-mahindra-off-campus-drive" },
  { name: "ZenQ Recruitment Test", path: "/zenq-recruitment-test" },
  { name: "MyAnatomy", path: "/myanatomy" },
];

export const competitiveExams = [
  { name: "UPSC Civil Services Examination (CSE)", path: "/upsc-cse" },
  { name: "IIT JEE", path: "/iit-jee" },
  { name: "NEET", path: "/neet" },
  { name: "GATE", path: "/gate" },
  { name: "CAT", path: "/cat" },
  { name: "UGC NET", path: "/ugc-net" },
  { name: "SSC Exams", path: "/ssc-exams" },
  { name: "Banking Exams", path: "/banking-exams" },
  { name: "State PSC Exams", path: "/state-psc-exams" },
  { name: "Railway Exams", path: "/railway-exams" },
  { name: "Defense Exams", path: "/defense-exams" },
  { name: "Judiciary Exams", path: "/judiciary-exams" },
  { name: "CA Exams", path: "/ca-exams" },
  { name: "CS Exams", path: "/cs-exams" },
  { name: "CLAT", path: "/clat" },
  { name: "NDA", path: "/nda" },
  { name: "ESE", path: "/ese" },
  { name: "CSIR NET", path: "/csir-net" },
  { name: "NTA UGC NET JRF", path: "/nta-ugc-net-jrf" },
  { name: "State TET Exams", path: "/state-tet-exams" },
];


